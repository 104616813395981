import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import {Table, Button} from 'antd'
import {getWatchRecords} from '../redux/watch/record'
import {WATCH_RECORD_STATUS, OCSP_STATUS} from '../utils/constant'

class WatchRecords extends React.Component {
  static props = {
    watchId: PropTypes.number,
    initRef: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {
      current: 1,
      offset: 0,
      limit: 10
    }
    this.searchWatchRecords()
    if (props.initRef) {
      props.initRef(this)
    }
  }

  searchWatchRecords = (state) => {
    const {offset, limit} = Object.assign({}, this.state, state)
    const {watchId} = this.props
    this.props.dispatch(getWatchRecords({watchId, offset, limit})).then((response) => {
      if (response.payload && response.payload.rows.length > 0) {
        if (response.payload.rows.some(item => item.status === WATCH_RECORD_STATUS.WATCHING)) {
          if (this.timer || this.isUnmounted) {
            return
          }
          this.timer = setInterval(() => {
            this.searchWatchRecords()
          }, 3 * 1000)
        } else {
          if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
          }
        }
      }
    })
  }

  componentWillUnmount () {
    this.isUnmounted = true
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  }

  get tableColumns () {
    return [{
      title: '监测时间',
      dataIndex: 'createTime',
      key: 'createTime',
      render: createTime => moment(createTime).format('YYYY-MM-DD HH:mm'),
      width: 142
    }, {
      title: '任务创建',
      dataIndex: 'isAuto',
      key: 'isAuto',
      render: (text, record) => record.isAuto ? '系统自动创建' : '用户手动创建',
      width: 98
    }, {
      title: '证书颁发机构',
      dataIndex: 'issuer',
      ellipsis: true,
      render: text => text || '-'
    }, {
      title: '证书状态',
      dataIndex: 'ocspStatus',
      key: 'ocspStatus',
      width: 98,
      render: (text, record) => {
        if (record.expiredTime && new Date(record.expiredTime).getTime() <= Date.now()) {
          return <span style={{color: 'red'}}>已过期</span>
        } else if (record.ocspStatus === OCSP_STATUS.GOOD) {
          return <span style={{color: 'green'}}>生效中</span>
        } else if (record.ocspStatus === OCSP_STATUS.REVOKED) {
          return <span style={{color: 'red'}}>已吊销</span>
        } else if (record.ocspStatus === OCSP_STATUS.UNKNOWN) {
          return <span style={{color: 'orange'}}>未知</span>
        } else {
          return '-'
        }
      }
    }, {
      title: '过期时间',
      dataIndex: 'expiredTime',
      key: 'expiredTime',
      width: 142,
      render: expiredTime => expiredTime ? moment(expiredTime).format('YYYY-MM-DD HH:mm') : '-'
    }, {
      title: '吊销时间',
      dataIndex: 'revokedTime',
      key: 'revokedTime',
      width: 142,
      render: revokedTime => revokedTime ? moment(revokedTime).format('YYYY-MM-DD HH:mm') : '-'
    }, {
      title: '任务状态',
      dataIndex: 'status',
      key: 'status',
      width: 142,
      render: (text, record) => {
        if (record.status === WATCH_RECORD_STATUS.WATCHING) {
          return <span style={{color: 'orange'}}>监测中</span>
        } else if (record.status === WATCH_RECORD_STATUS.WATCHED) {
          return <span style={{color: 'green'}}>已监测</span>
        } else if (record.status === WATCH_RECORD_STATUS.ERROR) {
          return <span style={{color: 'red'}}>{record.watchCertificateId ? '查询证书吊销状态失败' : '获取站点证书失败'}</span>
        }
      }
    }, {
      title: '失败信息',
      dataIndex: 'error',
      key: 'error',
      ellipsis: true,
      render: error => error || '-'
    }, {
      title: '操作',
      dataIndex: 'action',
      align: 'right',
      fixed: 'right',
      render: (text, record) => {
        if (record.status === WATCH_RECORD_STATUS.WATCHED) {
          return (
            <Button onClick={this.handleRecordDetail.bind(this, record.watchId, record.id)} size='small' type='link'>查看</Button>
          )
        } else {
          return <></>
        }
      }
    }]
  }

  get tableData () {
    return [...(new Array(this.state.offset)), ...this.props.records.rows.map((item, index) => {
      item.key = index + this.state.offset + 1
      return item
    })]
  }

  handleChangePage = (page, pageSize) => {
    const offset = pageSize * (page - 1)
    const limit = pageSize
    this.setState({
      current: page,
      offset,
      limit
    })
    this.searchWatchRecords({
      offset,
      limit
    })
  }

  handleChangePageSize = (current, size) => {
    const offset = 0
    const limit = size
    this.setState({
      current: 1,
      offset,
      limit
    })
    this.searchWatchRecords({
      offset,
      limit
    })
  }

  handleRecordDetail = (watchId, watchRecordId) => {
    this.props.history.push(`/monitor/watches/${watchId}/records/${watchRecordId}`)
  }

  render () {
    return (
      <Table
        columns={this.tableColumns}
        dataSource={this.tableData}
        size='small'
        scroll={{x: true}}
        pagination={{
          current: this.state.current,
          pageSize: this.state.limit,
          total: this.props.records.count,
          pageSizeOptions: ['10', '20', '50', '100'],
          showSizeChanger: true,
          showTotal: total => `共${total}条`,
          onChange: this.handleChangePage,
          onShowSizeChange: this.handleChangePageSize
        }}
      />
    )
  }
}

WatchRecords = connect((state, props) => {
  const watchId = props.watchId
  return {
    records: state.watch.record[`records_${watchId}`] || {count: 0, rows: []}
  }
})(WatchRecords)

WatchRecords = withRouter(WatchRecords)

export default WatchRecords
