import React from 'react'
import { connect } from 'react-redux'
import CertFlowImg from '../../assets/svg/certFlow.svg'
import { Icon, Input, Button } from 'antd'
import sectigoImg from '../../assets/svg/sectigo.svg'
import letsenryptImg from '../../assets/svg/letsencrypt.svg'

class Default extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      domain: '',
      email: ''
    }
  }

  componentDidMount() {
    if (window) {
      window.onkeypress = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault()
          e.stopPropagation()
          this.handleClickSignup()
        }
      }
    }
  }

  componentWillUnmount() {
    if (window) {
      window.onkeypress = null
    }
  }

  handleClickSignin = () => {
    this.props.history.push('/signin')
  }

  handleClickSignup = () => {
    const { domain, email } = this.state

    const params = []
    if (domain) {
      params.push(`domain=${encodeURIComponent(domain)}`)
    }
    if (email) {
      params.push(`email=${encodeURIComponent(email)}`)
    }

    if (params.length) {
      this.props.history.push(`/signup?${params.join('&')}`)
      return
    }
    this.props.history.push('/signup')
  }

  handleClickSubscribe = (subscribeId) => {
    this.props.history.push(`/signup?subscribeId=${subscribeId}`)
  }

  handleClickConnect = () => {
    this.props.history.push('/signup')
  }

  handleDomainChange = (e) => {
    this.setState({
      domain: e.target.value.trim()
    })
  }

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value.trim()
    })
  }

  render() {
    return (
      <div className="default" style={{ background: '#f2f3f4', color: 'black' }}>
        <div className='header' style={{ margin: "auto", display: 'flex', width: 1200, justifyContent: 'space-between', height: "80px", paddingTop: '30px' }}>
          <div className='left' style={{ display: 'flex', alignItems: "center" }}>
            <div className='logo' style={{ fontSize: '32px', display: "flex", justifyContent: "space-around", alignItems: "center" }}>
              <div style={{ borderRadius: '16px', height: "27px", width: "27px", border: "3px solid #0052d9", boxSizing: "border-box", marginRight: '2px', color: "black" }}></div>HTTPS
            </div>
          </div>
          <div className='right' style={{ display: 'flex', alignItems: "center" }}>
            <Button type="link" style={{ color: "black" }} href='#feature'>功能特性</Button>
            <Button type="link" style={{ color: "black" }} href="/docs/start" target="_blank">快速上手</Button>
            <Button type="link" style={{ color: "black" }} href="/docs" target="_blank">帮助文档</Button>
            <Button style={{ borderColor: "transparent", background: "rgba(0,0,0,.075)", color: "black" }} onClick={this.handleClickSignin} >登&nbsp;&nbsp;&nbsp;&nbsp;录</Button>
            <Button type="primary" style={{ marginLeft: '15px' }} onClick={this.handleClickSignup}>注&nbsp;&nbsp;&nbsp;&nbsp;册</Button>
          </div>
        </div>
        <div className='apply' style={{ width: 1200, margin: '25px auto 35px', display: 'flex', flexDirection: "column", padding: "30px 20px", color: "white", background: "#1c2035", boxShadow: 'rgba(0, 0, 0, 0.1) 1px 1px 4px 0px, rgba(0, 0, 0, 0.2) 2px 2px 16px 0px' }}>
          <div style={{ fontSize: "18px", marginBottom: "15px" }}>免费HTTPS证书(SSL证书)&nbsp;、&nbsp;自动化更新&nbsp;、&nbsp;自动化部署&nbsp;、&nbsp;自动化监控</div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              <div style={{ background: "#f2f3f4", height: 50, paddingLeft: '15px', display: "flex", justifyContent: "center", alignItems: "center", color: "black" }}>
                <img src="https://cdn.idevbase.com/assets/images/https.svg" alt='https' height={20} />&nbsp;
                <span>HTTPS</span>
              </div>
              <Input style={{ border: 'none', background: "#f2f3f4", width: 920, height: 50 }} value={this.state.domain} onChange={this.handleDomainChange} placeholder='请输入域名' />
            </div>
            <Button type="primary" style={{ width: 140, height: 50 }} onClick={this.handleClickSignup}>申请免费证书</Button>
          </div>
        </div>
        <div className='content' style={{ width: 1200, margin: '0px auto', display: 'flex', justifyContent: 'space-between', flexDirection: "column" }}>
          <div style={{ width: "100%", display: "flex" }}>
            <div style={{ width: "50%", display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
              <p style={{ fontSize: "16px", fontWeight: 600 }}>
                <img src="https://cdn.idevbase.com/assets/images/lighting.svg" alt='lighting' height={20} />
                <span>3分钟极速获取免费HTTPS证书(SSL证书)</span>
              </p>
              <p style={{ fontSize: "32px", width: "550px", color: "white" }}>
                <span style={{ background: "#0052d9", padding: '5px 20px', display: 'inline-block' }}>
                  OHTTPS致力于
                </span><br />
                <span style={{ background: "#0052d9", padding: '5px 20px', display: 'inline-block', marginTop: '1px' }}>
                  为用户提供
                </span><br />
                <span style={{ background: "#0052d9", padding: '5px 20px', display: 'inline-block', marginTop: '1px' }}>
                  零门槛、简单、高效
                </span><br />
                <span style={{ background: "#0052d9", padding: '5px 20px', display: 'inline-block', marginTop: '1px' }}>
                  的HTTPS证书(SSL证书)服务
                </span>
              </p>
              <p style={{ fontSize: "18px", marginBottom: '0px', width: "500px", fontWeight: 600 }}>
                支持证书一站式管理、自动化更新、自动化部署、自动化监控及告警等
              </p>
            </div>
            <div style={{ width: "50%", display: 'flex', justifyContent: "space-between" }}>
              <div style={{ background: "rgb(234, 249, 255)", boxShadow: 'rgba(0, 0, 0, 0.1) 1px 1px 4px 0px, rgba(0, 0, 0, 0.2) 2px 2px 16px 0px', width: 180, height: 350 }}>
                <div style={{ background: "white", padding: '15px', height: 185 }}>
                  <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end" }}>
                    <div style={{ fontSize: "48px", marginRight: '10px', fontWeight: "bold", color: "rgba(0, 0, 0, 0.50)" }}>1</div>
                    <p style={{ fontWeight: "bolder" }}>选择证书类型并输入域名</p>
                  </div>
                  <div>
                    <div style={{ color: "rgba(0, 0, 0, 0.50)", fontWeight: "bolder" }}><span style={{ color: "rgba(0, 0, 0, 0.65)" }}>•</span>&nbsp;单域名证书</div>
                    <div style={{ color: "rgba(0, 0, 0, 0.50)", fontWeight: "bolder" }}><span style={{ color: "rgba(0, 0, 0, 0.65)" }}>•</span>&nbsp;多域名证书</div>
                    <div style={{ color: "rgba(0, 0, 0, 0.50)", fontWeight: "bolder" }}><span style={{ color: "rgba(0, 0, 0, 0.65)" }}>•</span>&nbsp;泛域名证书</div>
                    <div style={{ color: "rgba(0, 0, 0, 0.50)", fontWeight: "bolder" }}><span style={{ color: "rgba(0, 0, 0, 0.65)" }}>•</span>&nbsp;输入域名</div>
                  </div>
                </div>
                <div style={{ padding: '15px', height: 150 }}>
                  <div style={{ fontWeight: "bolder" }}>
                    <span style={{ color: "green" }}>•</span>&nbsp;
                    <span style={{ color: "red" }}>•</span>&nbsp;
                    <span style={{ color: "red" }}>•</span>&nbsp;
                  </div>
                  <img style={{ display: 'block', margin: "18px auto" }} src="https://cdn.idevbase.com/assets/images/domain.svg" alt='domain' height="60" />
                </div>
              </div>
              <div style={{ background: "rgb(234, 249, 255)", boxShadow: 'rgba(0, 0, 0, 0.1) 1px 1px 4px 0px, rgba(0, 0, 0, 0.2) 2px 2px 16px 0px', width: 180, height: 350 }}>
                <div style={{ background: "white", padding: '15px', height: 185 }}>
                  <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end" }}>
                    <div style={{ fontSize: "48px", marginRight: '10px', fontWeight: "bold", color: "rgba(0, 0, 0, 0.50)" }}>2</div>
                    <p style={{ fontWeight: "bolder" }}>进行域名所有权验证</p>
                  </div>
                  <div>
                    <div style={{ color: "rgba(0, 0, 0, 0.50)", fontWeight: "bolder" }}><span style={{ color: "rgba(0, 0, 0, 0.65)" }}>•</span>&nbsp;DNS解析验证</div>
                    <div style={{ color: "rgba(0, 0, 0, 0.50)", fontWeight: "bolder" }}><span style={{ color: "rgba(0, 0, 0, 0.65)" }}>•</span>&nbsp;CNAME解析验证</div>
                    <div style={{ color: "rgba(0, 0, 0, 0.50)", fontWeight: "bolder" }}><span style={{ color: "rgba(0, 0, 0, 0.65)" }}>•</span>&nbsp;TXT文件验证</div>
                  </div>
                </div>
                <div style={{ padding: '15px', height: 150 }}>
                  <div style={{ fontWeight: "bolder" }}>
                    <span style={{ color: "green" }}>•</span>&nbsp;
                    <span style={{ color: "green" }}>•</span>&nbsp;
                    <span style={{ color: "red" }}>•</span>&nbsp;
                  </div>
                  <img style={{ display: 'block', margin: "10px auto" }} src="https://cdn.idevbase.com/assets/images/dns.svg" alt='dns' height="80" />
                </div>
              </div>
              <div style={{ background: "rgb(234, 249, 255)", boxShadow: 'rgba(0, 0, 0, 0.1) 1px 1px 4px 0px, rgba(0, 0, 0, 0.2) 2px 2px 16px 0px', width: 180, height: 350 }}>
                <div style={{ background: "white", padding: '15px', height: 185 }}>
                  <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end" }}>
                    <div style={{ fontSize: "48px", marginRight: '10px', fontWeight: "bold", color: "rgba(0, 0, 0, 0.50)" }}>3</div>
                    <p style={{ fontWeight: "bolder" }}>颁发证书并下载证书文件</p>
                  </div>
                  <div>
                    <div style={{ color: "rgba(0, 0, 0, 0.50)", fontWeight: "bolder" }}><span style={{ color: "rgba(0, 0, 0, 0.65)" }}>•</span>&nbsp;证书私钥文件</div>
                    <div style={{ color: "rgba(0, 0, 0, 0.50)", fontWeight: "bolder" }}><span style={{ color: "rgba(0, 0, 0, 0.65)" }}>•</span>&nbsp;证书文件</div>
                    <div style={{ color: "rgba(0, 0, 0, 0.50)", fontWeight: "bolder" }}><span style={{ color: "rgba(0, 0, 0, 0.65)" }}>•</span>&nbsp;证书格式转换</div>
                  </div>
                </div>
                <div style={{ padding: '15px', height: 150 }}>
                  <div style={{ fontWeight: "bolder" }}>
                    <span style={{ color: "green" }}>•</span>&nbsp;
                    <span style={{ color: "green" }}>•</span>&nbsp;
                    <span style={{ color: "green" }}>•</span>&nbsp;
                  </div>
                  <img style={{ display: 'block', margin: "18px auto" }} src="https://cdn.idevbase.com/assets/images/cert.svg" alt='cert' height="60" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='certificate' style={{ width: 1200, margin: '35px auto', display: 'flex', justifyContent: 'space-between', flexDirection: "column", alignItems: "center", boxShadow: 'rgba(0, 0, 0, 0.1) 1px 1px 4px 0px, rgba(0, 0, 0, 0.2) 2px 2px 16px 0px' }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ width: '50%', background: 'rgba(255, 255, 255, 0.5)', padding: '30px 40px', height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <div style={{ color: 'rgb(39, 53, 94)' }}>
                <div style={{ fontSize: '18px', fontWeight: '600', height: 50, display: 'flex', alignItems: 'center' }}>
                  <img height={50} style={{ transform: 'translateY(-10px)', marginRight: '12px' }} src={letsenryptImg} alt="Let's Encrypt" /> - 免费版HTTPS证书(SSL证书)
                </div>
                <div style={{ letterSpacing: '0.15rem', marginTop: '8px', color: 'rgba(39, 53, 94, 0.8)' }}>Let's Encrypt是世界顶级权威证书颁发机构，它是免费、开放和自动化的证书颁发机构，已服务超过45000万网站</div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'rgba(39, 53, 94, 1)', marginTop: '15px', height: 110, justifyContent: 'space-between' }}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', height: 30 }}>
                  <div>单域名证书<span style={{ fontSize: '12px' }}>·有效期90天</span></div>
                  <div style={{ fontSize: '14px', fontWeight: '600' }}>免费</div>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', height: 30 }}>
                  <div>多域名证书<span style={{ fontSize: '12px' }}>·有效期90天</span></div>
                  <div style={{ fontSize: '14px', fontWeight: '600' }}>免费</div>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', height: 30 }}>
                  <div>泛域名证书<span style={{ fontSize: '12px' }}>·有效期90天</span></div>
                  <div style={{ fontSize: '14px', fontWeight: '600' }}>免费</div>
                </div>
              </div>
            </div>
            <div style={{ width: '50%', background: 'rgb(88, 99, 130)', padding: '30px 40px', height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <div style={{ color: 'white' }}>
                <div style={{ fontSize: '18px', fontWeight: '600', height: 50, display: 'flex', alignItems: 'center' }}>
                <img height={28} style={{ marginRight: '8px' }} src={sectigoImg} alt="Sectigo" /> - 专业版HTTPS证书(SSL证书)
                </div>
                <div style={{ letterSpacing: '0.15rem', marginTop: '8px', color: 'rgba(255, 255, 255, 0.8)' }}>Sectigo (原Comodo)是全球SSL证书市场占有率第一的CA品牌，其产品安全、兼容性广泛、价格优惠，受到大量站长的信任和欢迎</div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'white', marginTop: '15px', height: 110, justifyContent: 'space-between' }}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', height: 30 }}>
                  <div>单域名证书<span style={{ fontSize: '12px' }}>·有效期365天</span></div>
                  <div style={{ fontSize: '12px' }}><span style={{ fontSize: '18px', fontWeight: '600' }}>39.8</span>RMB/年</div>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', height: 30 }}>
                  <div>多域名证书<span style={{ fontSize: '12px' }}>·有效期365天</span></div>
                  <div style={{ fontSize: '12px' }}><span style={{ fontSize: '18px', fontWeight: '600' }}>39.8</span>RMB/单域名/年</div>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', height: 30 }}>
                  <div>泛域名证书<span style={{ fontSize: '12px' }}>·有效期365天</span></div>
                  <div style={{ fontSize: '12px' }}><span style={{ fontSize: '18px', fontWeight: '600' }}>458</span>RMB/年</div>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', height: 30 }}>
                  <div>IP证书<span style={{ fontSize: '12px' }}>·有效期365天</span></div>
                  <div style={{ fontSize: '12px' }}><span style={{ fontSize: '18px', fontWeight: '600' }}>99.8</span>RMB/IP/年</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='content' style={{ width: 1200, margin: '35px auto', display: 'flex', justifyContent: 'space-between', flexDirection: "column", alignItems: "center" }}>
          <div style={{ fontSize: "24px", fontWeight: "600", color: "black", marginBottom: '12px' }}>证书支持自动化部署至</div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: '100%' }}>
            <a href='/docs/cloud/aliyun/' target='_blank'><img height="26" src="https://cdn.idevbase.com/assets/logo/ohttps_aliyun_with_name.svg" alt='阿里云' /></a>
            <a href='/docs/cloud/tcloud/' target='_blank'><img height="28" src="https://cdn.idevbase.com/assets/logo/ohttps_tcloud_with_name.svg" alt='腾讯云' /></a>
            <a href='/docs/cloud/bcloud/' target='_blank'><img height="34" src="https://cdn.idevbase.com/assets/logo/ohttps_bcloud_with_name.svg" alt='百度智能云' /></a>
            <a href='/docs/cloud/docker/' target='_blank'><img height="34" src="https://cdn.idevbase.com/assets/logo/ohttps_docker_with_name.svg" alt='Docker容器' style={{ margin: '0 -20px 0 -15px' }} /></a>
            <a href='/docs/cloud/btcn/' target='_blank'><img height="32" src="https://cdn.idevbase.com/assets/logo/ohttps_btcn_with_name.svg" alt='宝塔面板' /></a>
            <a href='/docs/cloud/qiniu/' target='_blank'><img height="28" src="https://cdn.idevbase.com/assets/logo/ohttps_qiniu_with_name.svg" alt='七牛云' /></a>
            <a href='/docs/cloud/upyun/' target='_blank'><img height="32" src="https://cdn.idevbase.com/assets/logo/ohttps_upyun_with_name.png" alt='又拍云' /></a>
            <a href='/docs/cloud/doge/' target='_blank'><img height="28" src="https://cdn.idevbase.com/assets/logo/ohttps_doge_with_name.png" alt='多吉云' /></a>
            <a href='/docs/cloud/docker/nginx/' target='_blank'><img height="28" src="https://cdn.idevbase.com/assets/logo/ohttps_nginx_with_name.svg" alt='NGINX' /></a>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: '700px', marginTop: '8px' }}>
            <a href='/docs/cloud/synology/' target='_blank' style={{fontSize: '20px', color: 'black', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><img height="28" src="https://cdn.idevbase.com/assets/logo/ohttps_synology_with_name.png" alt='群晖NAS' /><span style={{marginLeft: '0px'}}>群晖</span></a>
            <a href='/docs/cloud/docker/openresty/' target='_blank'><img height="48" src="https://cdn.idevbase.com/assets/logo/ohttps_openresty_with_name.png" alt='OpenResty' style={{transform: 'translateY(-6px)'}}/></a>
            <a href='/docs/cloud/webhook/' target='_blank' style={{fontSize: '20px', color: 'rgb(48, 62, 70)', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><img height="40" src="https://cdn.idevbase.com/assets/logo/ohttps_webhook.png" alt='WEBHOOK' /><span style={{marginLeft: '-2px'}}>Webhook</span></a>
            <a href='/docs/cloud/ssh/' target='_blank' style={{fontSize: '20px', color: 'black', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><img height="36" src="https://cdn.idevbase.com/assets/logo/ohttps_ssh.png" alt='SSH远程命令' /><span style={{marginLeft: '3px'}}>SSH</span></a>
            <a href='/docs/cloud/api/' target='_blank' style={{fontSize: '20px', color: 'black', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><img height="40" src="https://cdn.idevbase.com/assets/logo/ohttps_api.png" alt='API接口' /><span style={{marginLeft: '2px'}}>API接口</span></a>
          </div>
        </div>
        <div id="feature" style={{ background: "white" }}>
          <div className='content' style={{ width: 1200, margin: '0px auto', display: 'flex', justifyContent: 'space-between', flexDirection: "column", padding: "35px 0" }}>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
              <div style={{ width: 260 }}>
                <div style={{ height: 60, width: 60, display: 'flex', justifyContent: 'center' }}>
                  <img src="https://cdn.idevbase.com/assets/images/org.svg" alt="Let's Encrypt" height='60' />
                </div>
                <div style={{ fontSize: '16px', fontWeight: 'bold', margin: "8px 0" }}>
                  顶级证书颁发机构
                </div>
                <div style={{ color: "rgba(0, 0, 0, 0.8)" }}>
                  OHTTPS 证书全部由世界顶级权威证书颁发机构&nbsp;<a href="https://letsencrypt.org/" target='_blank' rel="noopener noreferrer" style={{ fontWeight: 600 }}>Let's Encrypt</a>&nbsp;或 &nbsp;<a href="https://www.sectigo.com/" target='_blank' rel="noopener noreferrer" style={{ fontWeight: 600 }}>Sectigo(原Comodo)</a>&nbsp;颁发，它们已为<strong style={{ color: 'black' }}>数亿万</strong>网站提供稳定可靠的HTTPS证书(SSL证书)服务
                </div>
              </div>
              <div style={{ width: 260 }}>
                <div style={{ height: 60, width: 60, display: 'flex', justifyContent: 'center' }}>
                  <img src="https://cdn.idevbase.com/assets/images/https.svg" alt='https' height='53' />
                </div>

                <div style={{ fontSize: '16px', fontWeight: 'bold', margin: "8px 0" }}>
                  受所有浏览器信任
                </div>
                <div style={{ color: "rgba(0, 0, 0, 0.8)" }}>
                  OHTTPS 证书兼容<strong style={{ color: 'black' }}>99.99%</strong>以上的浏览器，受所有主流操作系统和浏览器信任，保护您的网站用户信息不受中间人攻击
                </div>
              </div>
              <div style={{ width: 260 }}>
                <div style={{ height: 60, width: 60, display: 'flex', justifyContent: 'center' }}>
                  <img src="https://cdn.idevbase.com/assets/images/auto.svg" alt='auto' height='51' />
                </div>
                <div style={{ fontSize: '16px', fontWeight: 'bold', margin: "8px 0" }}>
                  自动化更新部署
                </div>
                <div style={{ color: "rgba(0, 0, 0, 0.8)" }}>
                  OHTTPS 可在证书到期前进行自动化更新和部署，支持部署至各大云计算平台的CDN、OSS、LB等、宝塔面板、群晖NAS、Docker、SSH、API、Webhook等，解决用户证书多平台部署及维护繁杂的问题
                </div>
              </div>
              <div style={{ width: 260 }}>
                <div style={{ height: 60, width: 60, display: 'flex', justifyContent: 'center' }}>
                  <img src="https://cdn.idevbase.com/assets/images/watch.svg" alt='watch' height='55' />
                </div>
                <div style={{ fontSize: '16px', fontWeight: 'bold', margin: "8px 0" }}>
                  自动化监控告警
                </div>
                <div style={{ color: "rgba(0, 0, 0, 0.8)" }}>
                  OHTTPS 提供证书即将到期告警及站点证书监控服务，支持指定域名、IP、端口进行监控，支持多种告警通知方式，包括邮件、钉钉、企业微信、Slack、Discord、Teams等
                </div>
              </div>
            </div>
            <div style={{ marginTop: "35px", display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '50%', paddingRight: "30px", justifyContent: "space-around" }}>
                <div>
                  <span style={{ background: '#586382', fontSize: "19px", fontWeight: "bold", padding: "10px", lineHeight: "30px", color: "white", display: 'inline-block' }}>
                    一站式管理您所有的HTTPS证书(SSL证书)
                  </span>
                  <br />
                  <span style={{ background: '#586382', fontSize: "19px", fontWeight: "bold", padding: "10px", lineHeight: "30px", color: "white", display: 'inline-block', marginTop: '1px' }}>
                    自动化更新、部署、监控、告警
                  </span>
                </div>
                <div style={{ display: 'flex', flexDirection: "row", color: "rgba(0, 0, 0, 0.8)" }}>
                  <div style={{ display: 'flex', flexDirection: "column", justifyContent: "space-between", height: 150 }}>
                    <div style={{ fontWeight: "bold" }}>
                      <Icon type="check-circle" theme="filled" />&nbsp;单域名证书</div>
                    <div style={{ fontWeight: "bold" }}>
                      <Icon type="check-circle" theme="filled" />&nbsp;多域名证书</div>
                    <div style={{ fontWeight: "bold" }}>
                      <Icon type="check-circle" theme="filled" />&nbsp;泛域名证书</div>
                    <div style={{ fontWeight: "bold" }}>
                      <Icon type="check-circle" theme="filled" />&nbsp;DNS解析验证</div>
                    <div style={{ fontWeight: "bold" }}>
                      <Icon type="check-circle" theme="filled" />&nbsp;CNAME解析验证</div>
                    <div style={{ fontWeight: "bold" }}>
                      <Icon type="check-circle" theme="filled" />&nbsp;证书文件格式转换</div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: "column", justifyContent: "space-between", marginLeft: "60px", height: 150 }}>
                    <div style={{ fontWeight: "bold" }}>
                      <Icon type="check-circle" theme="filled" />&nbsp;证书自动化更新</div>
                    <div style={{ fontWeight: "bold" }}>
                      <Icon type="check-circle" theme="filled" />&nbsp;证书自动化部署</div>
                    <div style={{ fontWeight: "bold" }}>
                      <Icon type="check-circle" theme="filled" />&nbsp;证书自动化监控</div>
                    <div style={{ fontWeight: "bold" }}>
                      <Icon type="check-circle" theme="filled" />&nbsp;证书自动化告警</div>
                    <div style={{ fontWeight: "bold" }}>
                      <Icon type="check-circle" theme="filled" />&nbsp;数十种部署类型</div>
                    <div style={{ fontWeight: "bold" }}>
                      <Icon type="check-circle" theme="filled" />&nbsp;多种告警通知方式</div>
                  </div>
                </div>
              </div>
              <div style={{ width: "50%" }}>
                <object type="image/svg+xml" data={CertFlowImg} aria-labelledby="证书由顶级证书机构颁发"></object>
              </div>
            </div>
          </div>
        </div>
        <div id="stats" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', background: '#2977FC', textAlign: 'center', position: 'relative', overflow: 'hidden', height: 480, color: 'white' }}>
          <div style={{ display: 'block', position: 'absolute', left: 0, top: 0, opacity: 0.1, background: 'url(https://cdn.idevbase.com/assets/images/stats.svg)', width: '100%', height: 600, backgroundSize: 250, backgroundPosition: '50%' }}></div>
          <p style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '15px' }}>100000+网站选择使用OHTTPS</p>
          <p style={{ width: 850 }}>我们致力于帮助用户获得免费的HTTPS证书(SSL证书)，并为其提供证书自动化更新、自动化部署、自动化监控服务<br />帮助其构建安全的互联网应用</p>
          <div style={{ margin: '15px 0px 30px' }}>
            <Button style={{ backgroundColor: 'rgb(28, 32, 53)', borderColor: 'rgb(28, 32, 53)', color: "white", padding: '0 25px', height: 50, boxShadow: 'rgba(0, 0, 0, 0.1) 1px 1px 4px 0px, rgba(0, 0, 0, 0.2) 2px 2px 16px 0px' }} onClick={this.handleClickSignup}>开始加密你的网站</Button> - 免费的HTTPS证书(SSL证书)
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: 'white', width: 900, color: 'black', padding: '30px 90px 17px', boxShadow: 'rgba(0, 0, 0, 0.1) 1px 1px 4px 0px, rgba(0, 0, 0, 0.2) 2px 2px 16px 0px' }}>
            <div><Icon type="line-chart" style={{ fontSize: '45px' }} /><p style={{ fontSize: '16px', fontWeight: 'bold', margin: '10px 0 0px' }}>10000+</p><p style={{ color: 'rgba(0, 0, 0, 0.8)' }}>每月生成证书数量</p></div>
            <div><Icon type="safety" style={{ fontSize: '45px' }} /><p style={{ fontSize: '16px', fontWeight: 'bold', margin: '10px 0 0px' }}>99.99%</p><p style={{ color: 'rgba(0, 0, 0, 0.8)' }}>浏览器及系统兼容性</p></div>
            <div><Icon type="cluster" style={{ fontSize: '45px' }} /><p style={{ fontSize: '16px', fontWeight: 'bold', margin: '10px 0 0px' }}>{parseInt((Date.now() - new Date('2019-12-23T00:13:35.000Z').getTime()) / 1000 / 60 / 60 / 24)}天</p><p style={{ color: 'rgba(0, 0, 0, 0.8)' }}>服务持续稳定运行</p></div>
          </div>
        </div>
        <div className='footer' style={{ background: "rgb(28, 32, 53)", color: 'white' }}>
          <div className='footer' style={{ width: 1200, margin: "auto", display: 'flex', flexDirection: 'column', padding: '30px 0 40px' }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', borderBottom: "0.5px solid", padding: '10px 0 25px' }}>
              <div style={{ fontSize: "24px", fontWeight: '600' }}>OHTTPS</div>
              <div style={{ display: "flex", justifyContent: "flex-end", alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', whiteSpace: "nowrap" }}>注册并开始申请证书？&nbsp;</div>
                <Input placeholder='请输入邮箱' style={{ height: 38, width: 250, border: 'none' }} value={this.state.email} onChange={this.handleEmailChange} />
                <Button style={{ backgroundColor: '#0052d9', borderColor: '#0052d9', color: "white", height: 38 }} onClick={this.handleClickSignup}>注册</Button>
              </div>
            </div>
            <div>
            </div>
            <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'flex-start', marginTop: '20px' }}>
              <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'flex-start', flexDirection: "row", width: 500 }}>
                <div>
                  <div style={{ fontWeight: "bold", fontSize: "14px", marginBottom: "10px" }}>OHTTPS</div>
                  <div>
                    <div><a href='/docs' target='_blank' style={{ color: 'white' }}>关于我们</a></div>
                    <div><a href='mailto:service@ohttps.com' target='_blank' rel="noopener noreferrer" style={{ color: 'white' }}>联系我们</a></div>
                    <div><a href='/docs/price' target='_blank' style={{ color: 'white' }}>服务计费</a></div>
                  </div>
                </div>
                <div>
                  <div style={{ fontWeight: "bold", fontSize: "14px", marginBottom: "10px" }}>解决方案</div>
                  <div>
                    <div>证书自动化更新</div>
                    <div style={{ marginTop: '2px' }}>证书自动化部署</div>
                    <div style={{ marginTop: '2px' }}>证书自动化监控</div>
                  </div>
                </div>
                <div>
                  <div style={{ fontWeight: "bold", fontSize: "14px", marginBottom: "10px" }}>帮助文档</div>
                  <div>
                    <div><a href='/docs/start' target='_blank' style={{ color: 'white' }}>快速上手</a></div>
                    <div style={{ marginTop: '2px' }}><a href='/docs/dns' target='_blank' style={{ color: 'white' }}>DNS授权</a></div>
                    <div style={{ marginTop: '2px' }}><a href='/docs/cloud' target='_blank' style={{ color: 'white' }}>部署节点</a></div>
                    <div style={{ marginTop: '2px' }}><a href='/docs/certformat' target='_blank' style={{ color: 'white' }}>证书格式</a></div>
                    <div style={{ marginTop: '2px' }}><a href='/docs/questions' target='_blank' style={{ color: 'white' }}>常见问题</a></div>
                  </div>
                </div>
                <div>
                  <div style={{ fontWeight: "bold", fontSize: "14px", marginBottom: "10px" }}>政策条款</div>
                  <div>
                    <div><a href='/docs/terms' target='_blank' style={{ color: 'white' }}>使用条款</a></div>
                    <div style={{ marginTop: '2px' }}><a href='/docs/privacy' target='_blank' style={{ color: 'white' }}>隐私政策</a></div>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: "column", justifyContent: "space-between", alignItems: "end", height: 144 }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: "center" }}>
                  联系我们：
                  <span style={{ fontSize: "18px", display: 'flex', justifyContent: "space-between", alignItems: "center", marginLeft: '4px' }}>
                    <a href='mailto:service@ohttps.com' target='_blank' rel="noopener noreferrer" style={{ color: 'white' }}><Icon type="mail" theme="filled" /></a>
                  </span>
                </div>
                <div style={{ color: "rgba(200, 200, 200, 0.8)" }}>
                  © 2019-{new Date().getFullYear()} OHTTPS All Rights Reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Default = connect()(Default)

export default Default
