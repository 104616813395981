import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import querystring from 'query-string'
import { Form, Table, Button, Input, message, Drawer, Modal } from 'antd'
import CloudServerAlias from '../../components/CloudServerAlias'
import { createCloudServer, getCloudServers, removeCloudServer } from '../../redux/cloud/server'
import CreateCloudServer from '../../components/CreateCloudServer'
import UpdateCloudServer from '../../components/UpdateCloudServer'

class CloudServers extends React.Component {
  constructor (props) {
    super(props)
    let {search, offset, limit} = querystring.parse(this.props.location.search)
    search = search || ''
    offset = parseInt(offset || 0)
    limit = parseInt(limit || 10)

    const current = parseInt(offset / limit) + 1

    this.state = {
      firstLoading: true,
      searchContent: search,
      current,
      offset,
      limit,
      activeCloudServerId: null,
      createCloudServerDrawerVisible: false,
      updateCloudServerDrawerVisible: false
    }
    this.searchCloudServers()
  }

  refreshUrl = ({searchContent, offset, limit}) => {
    this.props.history.replace(`${this.props.location.pathname}?search=${encodeURIComponent(searchContent)}&offset=${encodeURIComponent(offset)}&limit=${encodeURIComponent(limit)}`)
  }

  searchCloudServers = (state) => {
    const {searchContent, offset, limit} = Object.assign({}, this.state, state)

    this.refreshUrl({searchContent, offset, limit})

    this.props.dispatch(getCloudServers({searchContent, offset, limit})).then(() => {
      this.setState({
        firstLoading: false
      })
    })
  }

  get tableColumns () {
    return [{
      title: '部署节点ID/名称',
      dataIndex: 'id',
      render: (text, record) => {
        return (
          <>
            <Button onClick={this.handleClickCloudServer.bind(this, record.id)} type='link' style={{paddingLeft: '0px', paddingRight: '0px'}}>{record.name}</Button>
            <br/>
            <CloudServerAlias key={record.id} cloudServerId={record.id} cloudServerAlias={record.alias || record.name} onChangeComplete={this.searchCloudServers}/>
          </>
        )
      }
    }, {
      title: '类型',
      dataIndex: 'cloudProviderName',
      render: (text, record) => {
        return (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <img src={record.cloudProviderLogo} alt='' width={15} height={15}/>&nbsp;{record.cloudProviderName}
          </div>
        )
      }
    }, {
      title: '部署位置',
      dataIndex: 'cloudPushPosition'
    }, {
      title: '关联证书数量',
      dataIndex: 'associatedCertificatesCount'
    }, {
      title: '创建时间',
      dataIndex: 'createTime',
      render: createTime => moment(createTime).format('YYYY-MM-DD HH:mm')
    }, {
      title: '操作',
      dataIndex: 'action',
      align: 'right',
      fixed: 'right',
      render: (text, record) => {
        return (
          <>
            <Button onClick={this.handleClickCloudServer.bind(this, record.id)} size='small' type='link'>查看</Button>
            |
            <Button onClick={this.handleUpdateCloudServer.bind(this, record)} size='small' type='link'>修改</Button>
            |
            <Button onClick={this.handleCopyCloudServer.bind(this, record)} size='small' type='link'>复制</Button>
            |
            <Button onClick={this.handleDeleteCloudServer.bind(this, record)} size='small' type='link'>删除</Button>
          </>
        )
      }
    }]
  }

  get tableData () {
    return [...(new Array(this.state.offset)), ...this.props.cloudServers.rows.map((item, index) => {
      item.key = index + this.state.offset + 1
      return item
    })]
  }

  handleChangePage = (page, pageSize) => {
    const offset = pageSize * (page - 1)
    const limit = pageSize
    this.setState({
      current: page,
      offset,
      limit
    })
    this.searchCloudServers({
      offset,
      limit
    })
  }

  handleChangePageSize = (current, size) => {
    const offset = 0
    const limit = size
    this.setState({
      current: 1,
      offset,
      limit
    })
    this.searchCloudServers({
      offset,
      limit
    })
  }

  handleCreateCloudServer = () => {
    this.props.history.push('/guide/createcloudserver')
  }

  handleClickCloudServer = (cloudServerId) => {
    this.props.history.push(`/monitor/cloudservers/${cloudServerId}`)
  }

  handleCreateCloudServerDrawerClose = () => {
    this.setState({
      createCloudServerDrawerVisible: false
    })
  }

  handleCreateCloudServerComplete = () => {
    this.setState({
      createCloudServerDrawerVisible: false
    })
    this.searchCloudServers()
  }

  handleUpdateCloudServerDrawerClose = () => {
    this.setState({
      updateCloudServerDrawerVisible: false
    })
  }

  handleUpdateCloudServerComplete = () => {
    this.setState({
      updateCloudServerDrawerVisible: false
    })
    this.searchCloudServers()
  }

  handleDeleteCloudServer = (cloudServer, e) => {
    e.preventDefault()
    e.stopPropagation()

    const cloudServerId = cloudServer.id
    const deleteCloudServer = async ({ forceDelete }) => {
      this.props.dispatch(removeCloudServer({cloudServerId, forceDelete})).then((result) => {
        if (result.errorMsg) {
          return message.error(result.errorMsg)
        } else {
          message.success('删除部署节点成功')
  
          this.searchCloudServers()
        }
      })
    }

    if (cloudServer.associatedCertificatesCount > 0) {
      Modal.confirm({
        title: '确认要删除该部署节点吗?',
        content: `${cloudServer.associatedCertificatesCount}个证书已配置该部署节点，删除后将自动解绑，请谨慎操作`,
        okType: 'primary',
        okButtonProps: {size: 'small'},
        cancelButtonProps: {size: 'small'},
        autoFocusButton: false,
        maskClosable: true,
        onOk: () => {
          deleteCloudServer({ forceDelete: true })
        },
        onCancel: () => {
        }
      })
    } else {
      deleteCloudServer({ forceDelete: true })
    }
  }

  handleCopyCloudServer = (cloudServer, e) => {
    e.preventDefault()
    e.stopPropagation()

    const cloudServerId = cloudServer.id
    this.props.dispatch(createCloudServer({sourceCloudServerId: cloudServerId})).then((result) => {
      if (result.errorMsg) {
        return message.error(result.errorMsg)
      } else {
        message.success('复制部署节点成功')

        this.searchCloudServers()
      }
    })
  }

  handleUpdateCloudServer = (cloudServer, e) => {
    e.preventDefault()
    e.stopPropagation()

    const cloudServerId = cloudServer.id
    if (cloudServer.associatedCertificatesCount > 0) {
      Modal.confirm({
        title: '确认要修改该部署节点吗?',
        content: `${cloudServer.associatedCertificatesCount}个证书已配置该部署节点，修改部署节点可能会导致证书部署失败`,
        okType: 'primary',
        okButtonProps: {size: 'small'},
        cancelButtonProps: {size: 'small'},
        autoFocusButton: false,
        maskClosable: true,
        onOk: () => {
          this.setState({
            activeCloudServerId: cloudServerId,
            updateCloudServerDrawerVisible: true
          })
        },
        onCancel: () => {
        }
      })
    } else {
      this.setState({
        activeCloudServerId: cloudServerId,
        updateCloudServerDrawerVisible: true
      })
    }
  }

  handleChangeSearchContent = (e) => {
    this.setState({
      searchContent: e.target.value
    })
  }

  handleClickSearch = () => {
    this.setState({
      current: 1,
      offset: 0,
      limit: 10
    })
    this.searchCloudServers({offset: 0, limit: 10})
  }

  renderCreateCloudServerDrawer = () => {
    const {createCloudServerDrawerVisible} = this.state
    if (!createCloudServerDrawerVisible) {
      return
    }

    return (
      <Drawer
          title='添加节点'
          placement='right'
          closable={false}
          width = {600}
          onClose={this.handleCreateCloudServerDrawerClose}
          visible={this.state.createCloudServerDrawerVisible}
        >
          <CreateCloudServer onCreateComplete={this.handleCreateCloudServerComplete}/>
      </Drawer>
    )
  }

  renderUpdateCloudServerDrawer = () => {
    const {updateCloudServerDrawerVisible, activeCloudServerId} = this.state
    if (!updateCloudServerDrawerVisible) {
      return
    }

    return (
      <Drawer
          title='修改节点'
          placement='right'
          closable={false}
          width = {600}
          onClose={this.handleUpdateCloudServerDrawerClose}
          visible={this.state.updateCloudServerDrawerVisible}
        >
          <UpdateCloudServer cloudServerId={activeCloudServerId} onUpdateComplete={this.handleUpdateCloudServerComplete}/>
      </Drawer>
    )
  }

  render () {
    const {searchContent, firstLoading} = this.state
    return (
      <div className='content'>
        <Form layout='inline' style={{marginBottom: '12px'}}>
          <Form.Item>
            <Input.Search placeholder="输入部署节点ID/名称搜索" onSearch={this.handleClickSearch} enterButton value={searchContent} onChange={this.handleChangeSearchContent} style={{width: '450px'}}/>
          </Form.Item>
          <Form.Item>
            <Button icon='plus' onClick={this.handleCreateCloudServer}>添加节点</Button>
          </Form.Item>
        </Form>
        <Table
          columns={this.tableColumns}
          dataSource={firstLoading ? [] : this.tableData}
          size='small'
          scroll={{x: true}}
          pagination={{
            current: this.state.current,
            pageSize: this.state.limit,
            total: this.props.cloudServers.count,
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
            showTotal: total => `共${total}条`,
            onChange: this.handleChangePage,
            onShowSizeChange: this.handleChangePageSize
          }}
        />
        {this.renderCreateCloudServerDrawer()}
        {this.renderUpdateCloudServerDrawer()}
      </div>
    )
  }
}

CloudServers = connect((state) => {
  return {
    cloudServers: state.cloud.server.cloudServers
  }
})(CloudServers)

export default CloudServers
